import { FileGroupPermissionListing } from "./file-group-permission-listing";
import { IntergrationPermissionListing } from "./intergration-permission-listing";
import { ProjectGroupPermissionListing } from "./project-group-permission-listing";
import { TablePermissionListing } from "./table-permission-listing";


export class Permissions {
    public securityAdmin: boolean = false;
    public fileGroups: FileGroupPermissionListing;
    public integrationPermissions: IntergrationPermissionListing;
    public projectGroups: ProjectGroupPermissionListing;
    public tables: TablePermissionListing;
    public disLocalhostPermission: boolean = false;
}